import React, { useEffect, useState } from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import { ApiHook } from '../../hooks/apiHook'
import { useTranslation } from 'react-i18next'
import playButton from '../../assests/images/play-button.png'
import CardPagination from '../../components/Common/CardPagination'
import noVideos from '../../assests/images/Na_Nov_26.jpg'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


function VideoDashboard() {
    const { data: categories } = ApiHook.CallVideoCategories()
    const [catId, setcatId] = useState(null)
    const [currentPage, setcurrentPage] = useState(1)
    const [length, setlength] = useState(9)
    const { data: videos } = ApiHook.CallVideos(catId, currentPage, length)
    const { t } = useTranslation()
    useEffect(() => {
        setcatId(categories?.data[0]?.id)
    }, [categories])
    const tabSelectHandler = (activeKey) => {
        setcatId(activeKey)
    }
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const encryptIds = (id) => {
        return encodeURIComponent(CryptoJS.AES.encrypt(id.toString(), secretKey).toString());
    }
    return (
        <>
            <div className="page_head_top">{t("training-videos")}</div>
            <div className='video-dashboard-container'>
                {categories?.data?.length == 0 &&
                    <div className="newsMainBg">
                        <img src="images/news-no-data.png" alt="" />
                        <div>{t("sorry_no_data_found")}</div>
                    </div>
                }
                {catId && (
                    <Tab.Container id="left-tabs-example video-categories-tab" defaultActiveKey={catId} onSelect={tabSelectHandler}>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div><button className='category-btn'>Video Categories</button></div>
                                <Card className='category_container' style={{ border: 0,boxShadow:'0 11px 14px rgba(0, 0, 0, 0.1)' }}>
                                    <Card.Body className='category_body p-4'>
                                        
                                        <Nav variant="pills" className="flex-column">
                                            {categories?.data &&
                                                categories?.data.map((item) => (
                                                    <Nav.Item key={item.id}>
                                                        <Nav.Link eventKey={item.id}>{item.name}</Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                        </Nav>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='col-lg-9'>
                            <div><button className='category-btn'style={{background:'transparent',color:'transparent'}}>Videos</button></div>
                                <Card className='video_container' style={{ border: 0,boxShadow:'0 11px 14px rgba(0, 0, 0, 0.1)' }}>
                                    <Card.Body className='videos_section d-flex flex-column'>
                                        <Tab.Content style={{ overflow: 'auto' }}>
                                            <Tab.Pane eventKey={catId}>
                                                <div className='masonry-grid'>
                                                    {videos?.data?.videos.length > 0 ? (
                                                        videos?.data?.videos?.map((item, key) => (
                                                            <div className='video-item' key={key}>
                                                                <img
                                                                    src={item?.videoThumbnail}
                                                                    alt="Thumbnail"
                                                                    className='video-thumbnail'
                                                                />
                                                                <Link
                                                                    to={`/training-video/${encryptIds(item.categoryId)}/${encryptIds(item.id)}`}
                                                                >
                                                                    <img
                                                                        src={playButton}
                                                                        alt='playButton'
                                                                        className='play-button w-25'
                                                                    />
                                                                </Link>
                                                            </div>
                                                        ))
                                                    )
                                                        :

                                                        videos?.data?.videos.length != 0 &&
                                                        <div>
                                                            {Array.from({ length: 10 }).map((_, index) => (
                                                                <Skeleton key={index} count={1} height={"250px"} style={{ marginBottom: '16px' }} />
                                                            ))}
                                                        </div>

                                                    }
                                                </div>
                                                {videos?.data?.videos.length == 0 &&
                                                    <div className='no-videos-container'>
                                                        <img src={noVideos} className='w-75' />
                                                        <p>{t('no_videos_found')}</p>
                                                    </div>
                                                }
                                            </Tab.Pane>
                                        </Tab.Content>
                                        <div className='pagination-wrapper'>
                                            {videos?.data?.totalVideos > 0 && (
                                                <CardPagination
                                                    length={length}
                                                    page={currentPage}
                                                    pageChangehandler={setcurrentPage}
                                                    totalEntry={videos?.data?.totalVideos}
                                                />
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>

                        </div>
                    </Tab.Container>

                )}

            </div>
        </>

    )
}

export default VideoDashboard