import React, { useEffect, useState } from "react";
import { ApiHook } from "../../../hooks/apiHook";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../../Currency/CurrencyConverter";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Select from 'react-select';
import { Category } from "@mui/icons-material";
import { decrementQuantity, incrementQuantity, setPurchaseLeadData, setQuantity } from "../../../store/reducers/crmReducer";
function LeadDetails({ options, handleNext, product, setproduct }) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const { category, price, language } = useSelector((state) => state?.crm?.purchaseLead)
    // const [quantity, setquantity] = useState(1)
    // const [language, setlanguage] = useState(null)
    const [selectedLang, setselectedLang] = useState(null)
    const [selectedCat, setselectedCat] = useState(null)
    const [Leadcategory, setLeadcategory] = useState(null)
    const [maxQuantity, setmaxQuantity] = useState(null)
    const [totalAmount, settotalAmount] = useState(0)
    const getRespectLeadData = ApiHook.CallGetAvailiableQuantity(selectedCat, selectedLang)
    // const qunatityChangeHandler = (e) => {
    //     const value = parseInt(e.target.value, 10);
    //     setquantity(isNaN(value) ? 1 : value);
    //     if (value > getQuantityDetails?.data?.quantity) {
    //         setquantity(getQuantityDetails?.data?.quantity)
    //     }
    // }

    // const minusHandler = () => {
    //     setquantity((prevQuantity) => (prevQuantity <= 1 ? 1 : prevQuantity - 1));
    // }

    // const plusHandler = () => {
    //     setquantity((prevQuantity) => Math.min(prevQuantity + 1, getQuantityDetails?.data?.quantity ?? 10));
    // }

    const selectCtgryHanlder = (value) => {
        setLeadcategory(value)
        setselectedCat(value.value)
        dispatch(setPurchaseLeadData({ category: value }))
        dispatch(setQuantity(1))
    }
    const selectLangHandler = (value) => {
        setselectedLang(value?.value)
        dispatch(setPurchaseLeadData({ language: value }))
        dispatch(setQuantity(1))

    }

    console.log("=====category==", Leadcategory)
    const handleNextHandler = () => {
        handleNext()
    }

    ///redux setup----
    const quantity = useSelector((state) => state?.crm.quantity);
    console.log("======quantity====", quantity)
    const getQuantityDetails = useSelector((state) => state?.crm?.purchaseLead);

    const minusHandler = () => {
        dispatch(decrementQuantity());
    };

    const plusHandler = () => {
        const maxQuantity = getRespectLeadData?.data?.quantity ?? 10;
        const newQuantity = Math.min(quantity + 1, maxQuantity);
        dispatch(setQuantity(newQuantity));


    };


    const quantityChangeHandler = (e) => {
        const value = Math.max(1, Math.min(e.target.value, getRespectLeadData?.data?.quantity ?? 10));
        dispatch(setQuantity(value));
    };
    useEffect(() => {
        if (getRespectLeadData?.data) {
            dispatch(setPurchaseLeadData({ price: getRespectLeadData?.data?.price * quantity }))
            if (getRespectLeadData?.data?.quantity == 0) {
                dispatch(setQuantity(0))
                dispatch(setPurchaseLeadData({ price: getRespectLeadData?.data?.price }))
            }
        } else if (category && language) {
            if (!selectedCat && !selectedLang) {
                setselectedCat(category.value)
                setselectedLang(language.value)
            }

        }
    }, [quantity, getRespectLeadData?.data])
    console.log("===================data===", category, language, price, quantity)
    console.log("=====getQuantityDetails?.data?.quantity", getRespectLeadData?.data)
    return (
        <>
            <div className="checkout_list_scrl">
                <div className="checkout_contant_cart_row">
                    {/* <div className="checkout_cnt_image">
          <img src={"/images/product2.jpg"} alt="" />
        </div> */}
                    <div className="checkout_cnt_product">
                        <span>{t("Category")}</span>
                        <Select
                            value={category}
                            onChange={selectCtgryHanlder}
                            options={
                                options?.interestOptions?.map((item) => {
                                    return {
                                        label: t(item.label),
                                        value: item.value
                                    }
                                })
                            }
                        />
                    </div>
                    <div className="checkout_cnt_product">
                        <span>{t("language")}</span>
                        <Select
                            value={language}
                            onChange={selectLangHandler}
                            options={options?.languageOptions}
                        />
                    </div>

                    <div className="checkout_cnt_qty"
                        style={{
                            pointerEvents: (!category && !price) || quantity <= 0 ? 'none' : '',
                            opacity: (!category && !price) || quantity <= 0 ? '0.5' : ''
                        }}
                    >
                        <span>{t("quantity")}</span>
                        <div class="qty-input">
                            <button class="qty-count qty-count--minus" data-action="minus" type="button" onClick={minusHandler}>-</button>
                            <input
                                class="product-qty"
                                type="number"
                                name="product-qty"
                                min="1"
                                max={getRespectLeadData?.data?.quantity ?? 10}
                                value={quantity}
                                onChange={quantityChangeHandler}
                            />
                            <button class="qty-count qty-count--add" data-action="add" type="button" onClick={plusHandler}>+</button>
                        </div>


                    </div>
                    <div className="checkout_cnt_ttl_amnt">
                        <span>{t("totalAmount")}</span>
                        <strong>
                            {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
                                price,
                                conversionFactor
                            )}`}
                        </strong>
                    </div>

                </div>

            </div>


            <div className="checkout_continuew_btn">
                {
                    getRespectLeadData?.data?.quantity >= 0 &&
                    <div className="mt-1"><label style={{ color: "red" }}>*</label>{" "}<span>{t('availiable_leads')} - {" "}
                        <span style={{ color: 'green' }}><strong>{getRespectLeadData?.data?.quantity}</strong></span>

                    </span></div>

                }
                <button
                    className="btn btn-primary checkout_cnt_btn"
                    onClick={handleNextHandler}
                    disabled={(!category || !language) || quantity == 0}
                >
                    {t("continue")}
                </button>

            </div>
        </>
    )
}

export default LeadDetails