import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import CurrencyConverter from "../../../Currency/CurrencyConverter";

function PurchaseOverview({ handleNext,handleBack}) {
    const { t } = useTranslation()
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const overview = useSelector((state) => state?.crm?.purchaseLead)
    const quantity = useSelector((state) => state?.crm.quantity);

    return (
        <div className="row row_top justify-content-center form-business">
            <div className="regsiter_second_step_bx">
                <div className="regsiter_overview_box container">
                    <div className="regsiter_step_1_view_left_sec_head purchase_summary_head">
                        <strong><h5>{t('order_details')}</h5></strong>
                    </div>
                    <div className="row p-2">
                        <div className="col-md-3 mb-3 regsiter_overview_col">
                            <label htmlFor="sponsor">{t("category")}</label>
                            <strong id="sponsor">{overview?.category?.label}</strong>
                        </div>
                        <div className="col-md-3 mb-3 regsiter_overview_col">
                            <label htmlFor="pv">{t("language")}</label>
                            <strong id="pv">{overview?.language?.label}</strong>
                        </div>
                        <div className="col-md-3 mb-3 regsiter_overview_col">
                            <label htmlFor="pv">{t("quantity")}</label>
                            <strong id="pv">{quantity}</strong>
                        </div>
                        <div className="col-md-3 mb-3 regsiter_overview_col">
                            <label htmlFor="pv">{t("price")}</label>
                            <strong>
                                {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
                                    overview?.price,
                                    conversionFactor
                                )}`}
                            </strong>
                        </div>
                    </div>

                </div>
                <hr />
            </div>

            <div className="checkout_continuew_btn d-flex justify-content-between">
                <button
                    className="btn btn-primary checkout_cnt_btn"
                    onClick={handleBack}
                >
                    {t("back")}
                </button>

                <button
                    className="btn btn-primary checkout_cnt_btn"
                    onClick={handleNext}
                >
                    {t("continue")}
                </button>
            </div>


        </div>
    )
}

export default PurchaseOverview