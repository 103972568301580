import React from "react";
import { useTranslation } from "react-i18next";
import RenewComponent from "../../components/Renew/RenewComponent";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useOutletContext } from 'react-router-dom';
const Renew = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user?.loginResponse?.user);
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );

  //------------------------------------------ API ----------------------------------------

  const data = ApiHook.CallGetSubscriptionDetails();
  const { refetchAppLayout } = useOutletContext()
  console.log("=============appLayout========",refetchAppLayout)

  return (
    <>
      <div className="page_head_top">{t("renew")}</div>
      {data &&
        <RenewComponent
          data={data}
          userData={userData ? JSON.parse(userData) : null}
          currency={userSelectedCurrency}
          refetchAppLayout={refetchAppLayout}
        />
      }
    </>
  );
};

export default Renew;
