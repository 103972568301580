import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { privateRoutes, publicRoutes, webRoutes } from "./Routes/router";
import MainLayout from "./Layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsAuthenticated,
  setLoginResponse,
  setPaymentStatus,
} from "./store/reducers/userReducer";
import { useEffect } from "react";
import { setLoginResponseCheck } from "./utils/checkStorage";
import Renew from "./views/renew/Renew";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user?.isAuthenticated);
  const paymentStatus = useSelector((state) => state.user?.paymentStatus);
  const token = !!localStorage.getItem("access-token");
  const isAuthenticated = isLogin || token;
  let paidStatus = localStorage.getItem("paidStatus") || paymentStatus

  console.log("======paidUser==", paidStatus, paymentStatus)
  useEffect(() => {
    if (token) {
      const data = setLoginResponseCheck();
      dispatch(setLoginResponse(data));
      dispatch(setIsAuthenticated(true));
      dispatch(setIsAuthenticated(data.paymentStatus));
    }
  }, [dispatch, token]);


  const renderPrivateRoutes = () => {
    return privateRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  const renderWebRoutes = () => {
    return webRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };

  const renderPublicRoutes = () => {
    return publicRoutes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ));
  };
  return (
    <BrowserRouter>
      <Routes>
        {isAuthenticated ? (
          paidStatus === "false" ? (
            <Route path="/" element={<MainLayout />}>
              <Route path="renew" element={<Renew />} />
              <Route path="*" element={<Navigate to="/renew" replace />} />
            </Route>
          ) : (
            <>
              <Route path="/" element={<MainLayout />}>
                {renderPrivateRoutes()}
                {renderWebRoutes()}
              </Route>
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </>
          )
        ) : (
          <>
            {renderPublicRoutes()}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        )}
      </Routes>
    </BrowserRouter>


  );
}

export default App;
