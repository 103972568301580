import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PolicyTable from '../../components/policy/PolicyTable';
function PolicyDetails() {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1);
    return (
        <>
            <div className="page_head_top">{t("policy")}</div>
            <PolicyTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    )
}

export default PolicyDetails