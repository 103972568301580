import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  purchaseLead: {
    price: 0,
    category: null,
    language: null
  },
  quantity: 1,

};

const leadPurchaseSlice = createSlice({
  name: 'leadPurchase',
  initialState,
  reducers: {
    setPurchaseLeadData: (state, action) => {
      state.purchaseLead = {
        ...state.purchaseLead,
        ...action.payload
      }
    },
    incrementQuantity: (state, action) => {
      // Ensure incremented quantity doesn't exceed available stock
      state.quantity = Math.min(state.quantity + 1, action.payload);
      
    },
    decrementQuantity: (state) => {
      // Decrement quantity, but don't allow it to go below 1
      state.quantity = state.quantity <= 1 ? 1 : state.quantity - 1;
    },
    setQuantity: (state, action) => {
      // Directly set the quantity within valid range
      state.quantity = action.payload;
    },

  },
});

export const { setPurchaseLeadData, incrementQuantity, decrementQuantity, setQuantity } = leadPurchaseSlice.actions;

export default leadPurchaseSlice.reducer;
