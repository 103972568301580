import React from 'react'

function crm() {
  return (
    <>
      <iframe
        src='https://app.flowinternationalgroup.com/'
        width={"100%"}
        height="800"
      />
    </>
  )
}

export default crm