import API from "../../api/api";

const callGetApi = async (endpoint, params) => {
    try {
        const response = await API.get(endpoint, { params });
        if (response.status === 200) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        throw error;
    }
};

const VimeoService = {
    getVideoCatgories: async () => {
        return callGetApi(`video-categories`)
    },
    getVideos: async (catId, currentPage, length) => {
        try {
            const response = await API.get(`videos?catId=` + catId + '&page=' + currentPage + '&length=' + length);
            if (response.status === 200) {
                return response.data;
            } else {
                return response;
            }
        } catch (error) {
            throw error;
        }
    },
    getSingleVideo:async (vidId,catId) => {
        try {
            const response = await API.get(`single-video?vidId=` + vidId+'&catId='+catId);
            if (response.status === 200) {
                return response.data;
            } else {
                return response;
            }
        } catch (error) {
            throw error;
        }
    },
}

export default VimeoService;