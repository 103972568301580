import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../Common/buttons/SubmitButton';
import { Form } from 'react-bootstrap'
import { ApiHook } from '../../hooks/apiHook';
import { toast } from "react-toastify";
function UpgradeMembershipTab({ refetchProfile, setActiveTab }) {
    const { t } = useTranslation();
    const upgradeMembership = ApiHook.CallUpgradeMembership()
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const checkLicenceField = ApiHook.CallCheckLicenceField()
    const [fileErr, setfileErr] = useState('')
    const [file, setfile] = useState(null)
    const {
        register,
        watch,
        setValue,
        trigger,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const formValues = watch();
    const toggleEditMode = () => {
        setIsEditModeEnabled(!isEditModeEnabled);
    };
    const submitHandler = async (data) => {
        if (!file) return setfileErr(t('this_field_is_required'))
        data.file = file
        const response = await upgradeMembership.mutateAsync(data)
        if (response.status) {
            toast.success('Data added successfully')
            await refetchProfile()
            reset({
                license_num: "",
                confirm_membership: false
            })
            setIsEditModeEnabled(false)
            setActiveTab("firstTab")
        } else {
            toast.error('Data added failed')
        }

    }
    const fileChangeHandler = (e) => {
        setfile(e.target.files[0])
        setfileErr('')
    }
    return (
        <div id="sixthTab" className="tabcontent">
            <div className="editSec">
                <div className="editBg">
                    <span
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={toggleEditMode}
                    >
                        <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#32009c" }}
                        ></i>
                    </span>
                </div>
            </div>
            <h3>{t("license_details")}</h3>
            <div className="tabcontent_form_section">
                <div className="mb-1 row tabBlockClass">
                    <div className="col-md-11 col-sm-12 col-12">
                        {/* <label style={{ color: "red" }}>*</label>
                        {t("license_num")} */}
                        <Form.Label>{t("license_num")}</Form.Label>
                        <input
                            {...register("license_num", {
                                required: t("this_field_is_required"),
                                validate: async (value) => {
                                    const response = await checkLicenceField.mutateAsync({ licence_number: value });
                                    if (!response.status) {
                                        return t(response.data.description);
                                    } else {
                                        
                                        return true;
                                    }
                                },
                            })}
                            type="text"
                            id="name"
                            className="form-control"
                            disabled={!isEditModeEnabled}

                        />
                        {errors?.license_num && (
                            <span className="validation-error-message">
                                {errors?.license_num?.message}
                            </span>
                        )}
                    </div>

                </div>
                <div className="mb-3 row tabBlockClass">
                    <div className="col-md-11 col-sm-12 col-12">
                        {/* <label style={{ color: "red" }}>*</label>
                        {t("license_num")} */}
                        <Form.Label>{t("certificate")}</Form.Label>
                        <Form.Control type="file" onChange={fileChangeHandler} disabled={!isEditModeEnabled} />
                        {fileErr && (
                            <span className="validation-error-message">
                                {fileErr}
                            </span>
                        )}
                    </div>

                </div>
                <div className="mb-3 row tabBlockClass">

                    <div className="mb-3 row tabBlockClass">
                        <div className="col-md-12 col-sm-12 col-12">
                            <Form.Check
                                {...register('confirm_membership', {
                                    required: t("this_field_is_required"),
                                })}
                                inline
                                disabled={!isEditModeEnabled}
                                label={t('confirm_membership')}
                                type="checkbox"
                            />
                            {errors?.confirm_membership && (
                                <span className="validation-error-message">
                                    {errors?.confirm_membership?.message}
                                </span>
                            )}
                        </div>
                    </div>


                </div>

                <div
                    className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
                >
                    <SubmitButton
                        isSubmitting={!isEditModeEnabled}
                        className="btn"
                        text={"submit"}
                        click={handleSubmit(submitHandler)}
                    />
                </div>
            </div>
        </div>
    )
}

export default UpgradeMembershipTab