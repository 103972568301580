import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Vimeo from '@u-wave/react-vimeo';
import { useParams, NavLink } from 'react-router-dom'
import { ApiHook } from '../../hooks/apiHook';
import Accordion from 'react-bootstrap/Accordion';
import { Icon } from '@iconify/react'
import noVideo from '../../assests/images/no-video.png'
import CryptoJS from 'crypto-js';
function SingleVideoSection() {
    const { t } = useTranslation()
    const { catId, videoId } = useParams()
    const [vidId, setvidId] = useState(null)
    const [categoryId, setcategoryId] = useState(null)
    const { data: VideoData, isLoading } = ApiHook.CallSingleVideo(vidId, categoryId)

    const videoClickHandler = (vidId) => {
        setvidId(vidId)
    }
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const decryptId = (encryptedId) => {
        const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };
    useEffect(() => {
        const vidId = decryptId(videoId)
        setvidId(vidId)
        const catgryId = decryptId(catId)
        setcategoryId(catgryId)
    }, [videoId])

    return (
        <>
            <div className="page_head_top">{t("Training_video")}</div>
            <div className="single_video_contant_area_section">
                <div className="single_video_contant_area_head">
                    <h3>
                        <NavLink to={"/training-videos"}>
                            <i className="fa fa-angle-left"></i>
                        </NavLink>{" "}
                        {t("back")}
                    </h3>
                </div>
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='video-container'>
                            {
                                VideoData?.data &&
                                <Vimeo
                                    video={VideoData?.data?.video?.videoFile}
                                    responsive
                                    width={"100%"}
                                />
                            }

                        </div>
                        <div className='video-details mt-3'>
                            <h5>{VideoData?.data?.video?.videoTitle}</h5>
                            <p>{VideoData?.data?.video?.videoDescription}</p>
                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='vid_catgry_container'>
                            <Accordion flush alwaysOpen>
                                <Accordion.Item>
                                    <Accordion.Header>{VideoData?.data?.categoryName}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className="category_videos_list" style={{ listStyle: 'none', paddingLeft: '0' }}>
                                            {VideoData?.data?.OtherVideos.length > 0 ?
                                                VideoData?.data?.OtherVideos.map((item) => (
                                                    <li key={item.id} onClick={() => videoClickHandler(item.id)}
                                                        style={{ display: 'flex', alignItems: 'center',
                                                        color:item.id===VideoData?.data?.video?.id?'#00a3ff':''}}

                                                        
                                                    >
                                                        <Icon icon="noto:backhand-index-pointing-right" width="24" height="24" style={{ marginRight: '8px' }} />
                                                        {item.videoTitle}
                                                    </li>
                                                ))
                                                :

                                                !isLoading &&
                                                <div>
                                                    <img src={noVideo} className='w-75' />

                                                </div>

                                            }
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleVideoSection