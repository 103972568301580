import React, { useState } from 'react'
import { ApiHook } from '../../hooks/apiHook';
import TableMain from '../Common/table/TableMain';

function PolicyTable(props) {
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const policyData = ApiHook.CallPolicy(
        props.currentPage,
        itemsPerPage,
    );
    const headers = ["pol_number", "insured_person", "payment_mode", "trget_premium_amnt"]

    return (
        <div className="ewallet_table_section">
            <div className="table-responsive min-hieght-table">
                <TableMain
                    headers={headers}
                    data={policyData?.data?.tableData}
                    startPage={1}
                    currentPage={policyData?.data?.currentPage}
                    totalPages={policyData?.data?.totalPages}
                    setCurrentPage={props.setCurrentPage}
                    type={"policy"}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                />
            </div>

        </div>
    )
}

export default PolicyTable