import VimeoService from "../../services/vimeo/trainingVideos"

export const getVideoCatgories = async () => {
    try {
        const response = await VimeoService.getVideoCatgories()
        return response
    } catch (error) {
        return error.message
    }
}
export const getVideos = async (catId, currentPage, length) => {
    try {
        const response = await VimeoService.getVideos(catId, currentPage, length)
        return response
    } catch (error) {
        return error.message
    }
}
export const getSingleVideo = async (vidId,catId) => {
    try {
        const response = await VimeoService.getSingleVideo(vidId,catId)
        return response
    } catch (error) {
        return error.message
    }
}